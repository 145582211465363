<template>
    <div id="app">
        <a-modal
                :visible="show"
                title="添加/编辑管理员"
                :styles="styles"
                centered
                @ok="doSubmit"
                @cancel="_close">
            <a-form-model ref="appForm" :model="formData" :rules="formRule">
                <a-row :gutter="8">
                    <a-col span="8">
                        <a-form-model-item label="姓名" label-position="top" prop="name">
                            <a-input v-model="formData.name" placeholder="请输入姓名" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="6">
                        <a-form-model-item label="身份" label-position="top" prop="roleId">
                            <a-select v-model="formData.roleId" >
                                <a-select-option :value="item.roleId" v-for="(item,index) in roleList" :key="index">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="8">
                    <a-col span="8">
                        <a-form-model-item label="登录账号" label-position="top" prop="username">
                            <a-input v-model="formData.username" placeholder="建议使用手机号码" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="8">
                        <a-form-model-item label="登录密码" label-position="top" prop="password">
                            <a-input v-model="formData.password" type="password" placeholder="请输入登录密码" />
                        </a-form-model-item>
                    </a-col>
                    <a-col span="8">
                        <a-form-model-item label="是否启用" label-position="top">
                            <a-radio-group  button-style="solid" v-model="formData.status" :false-value="0" :true-value="1">
                                <a-radio-button :value="1">启用</a-radio-button>
                                <a-radio-button :value="0">禁用</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <a-spin size="large" fix v-if="loading"/>
        </a-modal>
    </div>
</template>

<script>
    import request from "@/config/request";
    import utils from "@/common/utils";
    export default {
        data(){
            return{
                show:false,
                loading:false,
                styles: {
                    width:'600px',
                    top:'80px'
                },
                roleList:[],
                formData: {
                    admId:'',
                    name:'',
                    roleId:'',
                    username:'',
                    password:'',
                    status:1
                },
                formRule:{
                    name:[{required:true,message:'请输入姓名'}],
                    roleId:[{required:true,message:'请选择身份'}],
                    username:[
                        {required:true,type:'string',message:'请输入登录账号'},
                        {type:'string',min:5,message: "请输入5位以上的登录账号"}
                    ],
                    password:[
                        {required:true,message:'请输入登录密码'},
                        {min:6,message: '密码长度至少6位'}
                    ]
                }
            }
        },
        mounted(){
            this.getRoleList();
        },
        methods:{
            _show(form){
                this.show = true;
                this.$nextTick(()=>{
                    if(form){
                        this.formData = JSON.parse(JSON.stringify(form));
                    }else{
                        delete this.formData.admId;
                    }
                })
            },
            getRoleList(){
                this.http.get("/platform/role/list",{page:1,limit:20}).then(ret=>{
                    this.roleList = ret.data.records;
                })
            },
            _close(){
                this.show = false;
                this.$refs.appForm.resetFields();
                this.$emit('close')
            },
            doSubmit(){
                this.$refs.appForm.validate((valid) => {
                    if (valid) {
                        // console.log(this.formData)
                        utils.loading();
                        request.post('/platform/admin/save',this.formData).then(ret=>{
                            // console.log(ret)
                            if(ret.code==200){
                                utils.success('操作成功！').then(()=>{
                                    this.$refs.appForm.resetFields();
                                    this.show = false;
                                    this.$emit('success');
                                })
                            }
                        }).catch(err=>{
                            console.log(err)
                        })
                    }
                })
            }
        },
        watch:{
            appId(appId){
                this.formData.appId = appId;
            }
        },
        props:{
            appId:{
                type:String|Number,
                default:()=>{
                    return "";
                }
            }
        }
    }
</script>

<style scoped>
    .demo-drawer-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
    }
</style>
